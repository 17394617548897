@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  
  .fade-in {
    animation: fadeIn 1s forwards;
  }
  
  .fade-out {
    animation: fadeOut 1s forwards;
  }
  