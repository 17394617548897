@use 'variables';

body {
    @apply text-foreground bg-background font-spaceMono pt-24 pb-12; // Ensure this matches the header and footer height
}

header {
    @apply bg-black h-24 fixed top-0 mt-0 left-0 right-0 z-50;
    & nav {
        @apply w-full max-w-screen-xl mx-auto my-6;
        & ul {
            @apply flex justify-end items-center space-x-6 list-none p-0 m-0;
            & li {
                @apply inline-block mt-4;
            }
        }
        & .header-icon {
            @apply cursor-pointer float-start justify-start bg-[url('/images/v-logo.svg')] bg-no-repeat bg-center bg-contain h-32 w-32;
        }
    }
}

h1 {
    @apply font-rubikGlitch text-6xl text-center m-0;
}

h1,
h3 {
    @apply font-rubikGlitch;
}

hr {
    @apply border-0 border-t-4 rounded-full border-yellow;
}

.navbar-link {
    @apply inline-flex text-center text-foreground transition-all duration-300 ease-in-out no-underline h-10;

    &:hover {
        @apply underline text-[1.1rem] transition-all ease-in-out;
    }

    &.active {
        @apply underline text-[1.1rem];
    }

    &.visited {
        @apply text-foreground transition-all duration-300 ease-in-out no-underline;
    }
}

.main-container {
    @apply mt-0 w-3/4 mx-auto;
}

.tooltip {
    @apply fixed bg-black text-yellow text-lg py-1 px-2 rounded pointer-events-none font-spaceMono opacity-0 transition-opacity ease-in-out;

    &.visible {
        @apply opacity-100 bg-opacity-80 text-opacity-100 border-none;
    }
}

.copy-button {
    @apply cursor-pointer border-none text-yellow text-xl inline-block items-center relative h-10;

    &:hover {
        @apply hover:underline border-yellow border-dashed text-[1.3rem] transition-all ease-in-out;
    }

    &:not(:hover) {
        @apply text-xl border-none transition-all ease-in-out;
    }
}

.center-content {
    @apply flex justify-center items-center text-center text-xl;

    & button {
        @apply bg-transparent border-none;
    }
}

.vision-content {
    @apply flex justify-center items-center w-2/3 mx-auto text-xl;
}

.socials-content {
    @apply inline-flex justify-center w-full items-center h-32 mx-auto text-yellow text-center;

    & button {
        @apply bg-transparent border-none;
    }

    & ul {
        @apply flex justify-center items-center space-x-8 list-none p-0 m-0;
    }

    & li {
        @apply inline-flex items-center justify-center h-16 w-16;
    }

    & a {
        @apply text-yellow text-6xl transition-all duration-300 ease-in-out flex items-center justify-center;
    }

    & a:hover {
        @apply text-[4.2rem] overflow-visible;
    }

    & svg {
        @apply transform transition-transform duration-300 ease-in-out;
        &:hover {
            @apply scale-125;
        }
    }
}

.telegram-icon {
    @apply inline-flex items-center justify-center h-full;

    & button {
        @apply bg-transparent border-none;
    }

    & > * {
        @apply transition-transform duration-300 ease-in-out;
        &:hover {
            @apply transition-all duration-300 ease-in-out size-[4.2rem] align-middle content-center;
        }

        &:not(:hover) {
            @apply transition-all duration-300 ease-in-out;
        }
    }
}

.buy-content {
    @apply inline-flex justify-center w-full items-center h-32 mx-auto text-center;

    & button {
        @apply bg-transparent border-none;
    }

    & ul {
        @apply flex justify-center items-center space-x-8 list-none p-0 m-0;
    }

    & li {
        @apply inline-flex items-center justify-center h-24 w-24;
    }

    & a {
        @apply text-white text-6xl transition-all duration-300 ease-in-out flex items-center justify-center;
    }

    & a:hover {
        @apply text-[4.2rem] overflow-visible;
    }

    & svg {
        @apply transform transition-transform duration-300 ease-in-out;
    }

    & a:hover svg {
        @apply scale-125;
    }
}

.uniswap-icon {
    @apply inline-flex items-center justify-center h-full;

    & > * {
        @apply transition-transform duration-300 ease-in-out;
        &:hover {
            @apply transition-all duration-300 ease-in-out size-[7.0rem] align-middle content-center text-uniswap-pink;
        }

        &:not(:hover) {
            @apply transition-all duration-300 ease-in-out;
        }
    }
}

.matcha-icon {
    @apply inline-flex items-center justify-center h-full;

    & > * {
        @apply transition-transform duration-300 ease-in-out;
        &:hover {
            @apply transition-all duration-300 ease-in-out size-[7.0rem] align-middle content-center text-matcha-green;
        }

        &:not(:hover) {
            @apply transition-all duration-300 ease-in-out;
        }
    }
}

.flooz-icon {
    @apply inline-flex items-center justify-center h-full;

    & > * {
        @apply transition-all duration-300 ease-in-out;
    }

    & .flooz-background {
        @apply text-white;
    }

    & .flooz-star {
        @apply text-black;
    }

    &:hover {
        @apply transition-all duration-300 ease-in-out;

        & .flooz-background {
            @apply text-flooz-blue transition-all duration-300 ease-in-out;
        }

        & .flooz-star {
            @apply text-white transition-all duration-300 ease-in-out;
        }

        & > * {
            @apply size-[7.0rem] align-middle content-center transition-all duration-300 ease-in-out;
        }
    }

    &:not(:hover) {
        @apply transition-all duration-300 ease-in-out;

        & .flooz-background {
            @apply transition-all duration-300 ease-in-out;
        }

        & .flooz-star {
            @apply transition-all duration-300 ease-in-out;
        }

        & > * {
            @apply transition-all duration-300 ease-in-out;
        }
    }
}

.tokenomics-content {
    @apply flex flex-col justify-center items-center text-center text-4xl w-full mx-auto;
    & ul {
        @apply text-2xl flex flex-row justify-center items-center space-x-4 list-none p-0 m-5;

        & li {
            @apply inline-block h-10;
        }

        & a {
            @apply text-yellow transition-all duration-300 ease-in-out no-underline h-10;

            &:hover {
                @apply transition-all duration-300 ease-in-out text-[1.75rem] underline;
            }

            &:not(:hover) {
                @apply transition-all duration-300 ease-in-out;
            }
        }
    }
}

footer {
    @apply bg-black h-10 fixed bottom-0 left-0 right-0 z-50 border-yellow border-solid border-0 border-t-[1px] flex items-center justify-center;

    & .footer-content {
        @apply text-center;

        & a {
            @apply no-underline mx-2 text-yellow;

            &:visited {
                @apply no-underline mx-2 text-yellow;
            }

            &:hover {
                @apply transition-all ease-linear underline underline-offset-8 font-extrabold;
            }

            &:not(:hover) {
                @apply transition-all ease-linear;
            }
        }
    }
}