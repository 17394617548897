.hero-section {
    position: relative;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  
  .hero-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    transition: opacity 1s ease-in-out;
  }
  
  .hero-content {
    position: relative;
    z-index: 1;
    text-align: center;
    color: #e0ff00;
  }
  